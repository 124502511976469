import { FunctionComponent } from 'react'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import { SectionBannerCarouselData } from 'data-access/sanity/fragments/sections/sectionBannerCarousel.fragment'

import SectionBannerCta from '../section-banner-cta/section-banner-cta'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import { useCookie } from 'react-use'

import { Button } from '@ui/components/ui/button'
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from '@ui/components/ui/dialog'

interface Props {
  data: SectionBannerCarouselData
  index: number
}

const SectionBannerCarousel: FunctionComponent<Props> = ({ data, index }) => {
  const Element = index === 0 ? 'header' : 'section'
  const [isOfficeRequestCookie] = useCookie('isOfficeRequest')

  if (data.slides.length === 0) {
    return null
  }

  return (
    <Element className='w-full bg-brand-grey text-brand-grey'>
      <Swiper
        threshold={5}
        touchAngle={25}
        slidesPerView={1}
        navigation={{
          enabled: true,
        }}
        pagination={{
          bulletClass: 'swiper-pagination-bullet !bg-white',
        }}
        modules={[Navigation, Autoplay, Pagination]}
        loop={true}
        autoplay={{ delay: data?.delay || 6000 }}
        initialSlide={0}
        speed={200}
        breakpoints={{
          0: {
            navigation: {
              enabled: false,
            },
            pagination: {
              enabled: true,
            },
          },
          1024: {
            navigation: {
              enabled: true,
            },
            pagination: {
              enabled: false,
            },
          },
        }}
      >
        {data.slides
          .filter((x) => x._id)
          .map((slide, i) => {
            if (i === 0 && isOfficeRequestCookie === 'true') {
              return (
                <SwiperSlide key={slide._id}>
                  <Dialog>
                    <div className='relative'>
                      <div className='hidden md:block'>
                        <img className='w-full' src='https://cdn.sanity.io/images/yb9xf4jc/production/0d711411317429bfe4e126621b3ab068df7bc5a8-4320x1440.jpg' alt='the squeezies' />
                      </div>
                      <div className='block md:hidden'>
                        <img className='w-full' src='https://cdn.sanity.io/images/yb9xf4jc/production/b09643b86d51ac1088a3b8966a53ca1c751fdcca-1125x1125.jpg' alt='the squeezies' />
                      </div>
                      <div className='absolute inset-0 flex flex-col items-center justify-center gap-2 p-10'>
                        <h1 className='font-ss25 text-heading-3-ss25 font-bold uppercase [text-shadow:2.484px_2.484px_0px_#C0172A] md:text-heading-1-ss25 md:[text-shadow:6px_6px_0px_#C0172A]'>
                          The Squeezies
                        </h1>
                        <p className='text-body-lg-bold md:text-body-xl-bold'>For when you just can't contain yourself</p>
                        <DialogTrigger asChild>
                          <Button className='mt-5' variant='primary'>
                            Shop Now
                          </Button>
                        </DialogTrigger>
                      </div>
                    </div>
                    <DialogContent>
                      <DialogTitle>April fools!</DialogTitle>
                      <DialogDescription>ps. this is only visible in our office ;)</DialogDescription>
                    </DialogContent>
                  </Dialog>
                </SwiperSlide>
              )
            }
            return (
              <SwiperSlide key={slide._id}>
                <SectionBannerCta index={index} data={slide} container='section' priority={index === 0 && i === 0} fixedAspectRatio={true} />
              </SwiperSlide>
            )
          })}
      </Swiper>
    </Element>
  )
}

export default SectionBannerCarousel
